<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/logo-dark.png" alt="" height="50">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark.png" alt="" height="50">
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/logo-light.png" alt="" height="50">
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light.png" alt="" height="50">
          </span>
        </a>
      </div>

      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex">

      <div class="dropdown d-none d-lg-inline-block me-1">
        <button type="button" class="btn header-item noti-icon" (click)="openModal(content)">
          Build Project
        </button>
      </div>

      <div class="dropdown d-none d-lg-inline-block me-1">
        <button type="button" class="btn header-item noti-icon" data-toggle="fullscreen" (click)="fullscreen()">
          <i class="bx bx-fullscreen"></i>
        </button>
      </div>
      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <img class="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg" alt="Header Avatar">
          <span class="d-none d-xl-inline-block ms-1">User</span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
          <!-- item-->
          <a class="dropdown-item" href="javascript: void(0);"><i class="bx bx-user font-size-16 align-middle me-1"></i>
            Profile</a>
          <a class="dropdown-item" href="javascript: void(0);"><i
              class="bx bx-wallet font-size-16 align-middle me-1"></i>Settings</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()"><i
              class="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i>
            Logout</a>
        </div>
      </div>
      <div class="dropdown d-inline-block">
        <button type="button" class="btn header-item noti-icon right-bar-toggle" (click)="onSettingsButtonClicked()">
          <i class="bx bx-cog bx-spin"></i>
        </button>
      </div>
    </div>
  </div>
</header>
<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
          <ng-container *ngFor="let item of menuItems">
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle arrow-none" *ngIf="!item.subItems" href="javascript: void(0);"
                id="topnav-components" [routerLink]="item.link" role="button">
                <i class="bx {{item.icon}} me-2"></i>{{ item.label | translate }} <div class="arrow-down"
                  *ngIf="hasItems(item)"></div>
              </a>

              <a *ngIf="item.subItems" class="nav-link dropdown-toggle arrow-none" (click)="onMenuClick($event)"
                href="javascript: void(0);" id="topnav-components" role="button">
                <i class="bx {{item.icon}} me-1"></i>
                {{item.label| translate }}
                <div class="arrow-down"></div>
              </a>

              <div class="dropdown-menu" [ngClass]="{'dropdown-mega-menu-xl px-2':  item.subItems.length > 11}"
                aria-labelledby="topnav-dashboard" *ngIf="hasItems(item)">
                <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                  <a *ngIf="item.subItems.length < 11 && !hasItems(subitem)" class="col dropdown-item side-nav-link-ref"
                    [routerLink]="subitem.link" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">
                    {{subitem.label | translate }}
                  </a>
                  <div *ngIf="item.subItems.length > 11">
                    <div *ngIf="i % 3 == 0" class="row">
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="subitem.link">{{item.subItems[i].label | translate}}</a></div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="item.subItems[i + 1]?.link">{{item.subItems[i + 1]?.label | translate}}</a>
                      </div>
                      <div class="col-lg-4"><a class="dropdown-item side-nav-link-ref" routerLinkActive="active"
                          [routerLink]="item.subItems[i + 2]?.link">{{item.subItems[i + 2]?.label | translate}}</a>
                      </div>
                    </div>
                  </div>

                  <div class="dropdown" *ngIf="hasItems(subitem)">
                    <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                      (click)="onMenuClick($event)">{{ subitem.label | translate }}
                      <div class="arrow-down"></div>
                    </a>
                    <div class="dropdown-menu">
                      <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                        <a class="dropdown-item side-nav-link-ref" *ngIf="!hasItems(subSubitem)"
                          href="javascript: void(0);" [routerLink]="subSubitem.link" routerLinkActive="active">{{
                          subSubitem.label | translate }}</a>
                        <div class="dropdown" *ngIf="hasItems(subSubitem)">

                          <a class="dropdown-item dropdown-toggle" href="javascript: void(0);"
                            (click)="onMenuClick($event)">{{ subSubitem.label | translate }}
                            <div class="arrow-down"></div>
                          </a>
                          <div class="dropdown-menu">
                            <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                              <a class="dropdown-item side-nav-link-ref" href="javascript: void(0);"
                                [routerLink]="subSubSubitem.link" routerLinkActive="active">{{ subSubSubitem.label |
                                translate }}</a>
                            </ng-template>
                          </div>
                        </div>
                      </ng-template>
                    </div>
                  </div>
                </ng-template>
              </div>
            </li>
          </ng-container>
        </ul>
      </div>
    </nav>
  </div>
</div>
<ng-template #content role="document" let-modal>
  <div class="modal-header">
    <h5 class="modal-title" id="exampleModalLabel">Build Project</h5>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" id="close-modal"
      (click)="modal.dismiss('Cross click')"></button>
  </div>
  <form>
    <div class="modal-body">
      <input type="hidden" name="id" value="" formControlName="ids" />
      <div class="row mb-4">
        <label for="projectname" class="col-form-label col-lg-2">Project Name</label>
        <div class="col-lg-10">
          <input id="projectname" name="projectname" type="text" class="form-control"
            placeholder="Enter Project Name...">
        </div>
      </div>
      <div class="row mb-4">
        <label class="col-form-label col-lg-2">Default Layout</label>
        <div class="col-lg-10">
          <div class="form-check form-switch mb-3">
            <input class="form-check-input" type="checkbox" checked id="flexSwitchCheckDefault">
            <label class="form-check-label" for="flexSwitchCheckDefault">Vertical Layout</label>
          </div>
        </div>
      </div>
      <div class="row mb-4">
        <label for="projectname" class="col-form-label col-lg-2">Logo</label>
        <div class="col-lg-10">
          <input type="file" class="form-control" id="inputGroupFile01">
        </div>
      </div>
      <div class="row mb-4">
        <label for="projectname" class="col-form-label col-lg-2">Sidebar Color</label>
        <div class="col-lg-10">
          <input class="form-control form-control-color mw-100" type="color" value="#2a3042" id="example-color-input">
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <div class="hstack gap-2 justify-content-end">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal"
          (click)="modal.close('Close click')">Close</button>
        <button type="submit" class="btn btn-success" id="add-btn">Download Project</button>
      </div>
    </div>
  </form>
</ng-template>