import { MenuItem } from './menu.model';

export const MENU: MenuItem[] = [
    {
        id: 1,
        label: 'Forms',
        icon: 'bx-collection',
        subItems: [
            {
                id: 2,
                label: 'Validation',
                link: '/form/validation'
            },
            {
                id: 3,
                label: 'Wizard',
                link: '/form/wizard'
            },
            {
                id: 4,
                label: 'Sample Forms',
                subItems: [
                    {
                        id: 5,
                        label: 'Sample 1',
                        link: '/form/controls'
                    },
                    {
                        id: 6,
                        label: 'Sample 2',
                        link: '/form/controls-1'
                    },
                    {
                        id: 7,
                        label: 'Sample 3',
                        link: '/form/controls-2'
                    },
                    {
                        id: 34,
                        label: 'Sample 4',
                        link: '/form/controls-3'
                    }
                ]
            }
        ]
    },
    {
        id: 8,
        label: 'Grid & Tables',
        icon: 'bx-list-ul',
        subItems: [
            {
                id: 9,
                label: 'Bootstrap Tables',
                link: '/tables/basic'
            },
            {
                id: 10,
                label: 'Tables',
                subItems: [
                    {
                        id: 11,
                        label: 'Sample 1',
                        link: '/tables/sample'
                    },
                    {
                        id: 12,
                        label: 'Sample 2',
                        link: '/tables/sample-1'
                    },
                    {
                        id: 13,
                        label: 'Sample 3',
                        link: '/tables/sample-2'
                    },
                    {
                        id: 14,
                        label: 'Sample 4',
                        link: '/tables/sample-3'
                    },
                    {
                        id: 16,
                        label: 'Expandable Table',
                        link: '/tables/advanced'
                    },
                    {
                        id: 17,
                        label: 'Editable Table',
                        link: '/tables/editable'
                    },
                    {
                        id: 17,
                        label: 'Excel Table',
                        link: '/#'
                    }]
            },
            {
                id: 15,
                label: 'Grid',
                subItems: [
                    {
                        id: 11,
                        label: 'Sample 1',
                        link: '/tables/grid'
                    },
                    {
                        id: 11,
                        label: 'Sample 2',
                        link: '/tables/grid-1'
                    }
                ]
            },
            
        ]
    },
    {
        id: 18,
        label: 'Components',
        icon: 'bx-list-ul',
        subItems: [
            {
                id: 19,
                label: 'Calendar',
                link: '/calendar',
            },
            {
                id: 20,
                label: 'Chat',
                link: '/chat',

            }
        ]
    },
    {
        id: 21,
        label: 'UI Elements',
        icon: 'bx-tone',
        subItems: [
            {
                id: 22,
                label: 'Modals',
                link: '/ui/modals',
                parentId: 21
            },
            {
                id: 23,
                label: 'Tabs & Accordions',
                link: '/ui/tabs-accordions',
                parentId: 21
            }

        ]
    },
    {
        id: 24,
        label: 'Icons',
        icon: 'bx-aperture',
        subItems: [
            {
                id: 25,
                label: 'Boxicons',
                link: '/icons/boxicons',
                parentId: 24
            },
            {
                id: 26,
                label: 'Material Design',
                link: '/icons/materialdesign',
                parentId: 24
            },
            {
                id: 27,
                label: 'Dripicons',
                link: '/icons/dripicons',
                parentId: 24
            },
            {
                id: 28,
                label: 'Font Awesome',
                link: '/icons/fontawesome',
                parentId: 24
            },
        ]
    },
    
];

