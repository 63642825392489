export const environment = {
  production: false,
  msalConfig: {
      auth: {
          clientId: '63f82618-a06c-460c-b33a-ab0dbfbea65a',
          authority: 'https://login.microsoftonline.com/7708ce37-5e53-44be-927e-69b78f7fabca'
      }
  },
  apiConfig: {
      scopes: ['user.read'],
      uri: 'https://graph.microsoft.com/v1.0/me'
  }
};
